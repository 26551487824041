import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import { PartialPlageWithPath } from '../infrastructure/model/Gatsby/VilleNodeInterface';

export default function PlageCard({ plage, description }: { plage: PartialPlageWithPath; description?: string }) {
  return (
    <Card>
      <CardActionArea component={Link} to={plage.path}>
        <CardMedia
          component="img"
          height="140"
          image="/images/illustration-plage.png"
          alt="image d'illustration non representative"
        />
        <CardContent>
          <Typography gutterBottom variant="h2">
            {plage.nom}
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 1.5 }} color="primary">
            {plage.ville.nom}
          </Typography>
          {description && (
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
