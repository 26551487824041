import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { matchSorter } from 'match-sorter';
import { Box, Divider, Fab, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Search, LocationCity } from '@mui/icons-material';
import { PartialPlageWithPath, VilleNodeInterface } from '../../infrastructure/model/Gatsby/VilleNodeInterface';
import { MarkdownRemarkPageDataInterface } from '../../infrastructure/model/Gatsby/MarkdownRemarkPageDataInterface';
import CustomizedPageHelmet from '../../components/CustomizedPageHelmet';
import PlageCard from '../../components/PlageCard';
import Layout from '../../components/Layout';
import { renderHast, splitHastUntil } from '../../infrastructure/hast';

export type PlageListItem = PartialPlageWithPath;

const matchPlages = (plages: PlageListItem[], searchValue?: string) => {
  if (!searchValue) {
    return plages;
  }

  return matchSorter<PlageListItem>(plages, searchValue, { keys: ['nom'] });
};

export interface VillePageProps {
  data: {
    ville: VilleNodeInterface;
    site: Queries.Site;
    markdownRemark?: MarkdownRemarkPageDataInterface;
  };
  params: {
    nom: string;
  };
  pageContext: { id: string };
}

const VillePage = ({
  data: {
    ville: { nom, departement, plages },
    markdownRemark,
    site,
  },
}: VillePageProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [matchingPlages, setMatchingPlages] = useState<PlageListItem[]>(plages);
  useEffect(() => {
    const matchings = matchPlages(plages, searchValue);
    setMatchingPlages(matchings);
  }, [searchValue, plages]);

  const [firstHalf, secondHalf] = splitHastUntil(markdownRemark?.htmlAst, ({ tagName }) => tagName === 'h2');

  return (
    <Layout>
      <CustomizedPageHelmet metadata={markdownRemark?.frontmatter} defaultTitle={site?.siteMetadata?.title} />

      {firstHalf ? (
        // @ts-ignore
        renderHast(firstHalf)
      ) : (
        <Typography variant="h1" textAlign="center">
          A la découverte des plages de <br />
          <Typography variant="h1" component="span" color="primary" sx={{ fontSize: '1.625rem' }}>
            {nom}
          </Typography>
        </Typography>
      )}

      <Box py={4}>
        <TextField
          id="outlined-basic"
          label="Recherchez votre plage"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Box>

      <Divider variant="middle" />

      <Box py={4}>
        {matchingPlages.length > 0 && (
          <Stack spacing={2}>
            {matchingPlages.map((plage) => (
              <PlageCard key={plage.id} plage={plage} />
            ))}
          </Stack>
        )}
      </Box>

      {secondHalf && <Box py={2}>{renderHast(secondHalf)}</Box>}

      <Fab
        variant="extended"
        color="primary"
        aria-label={`Voir les autres villes du département ${departement}`}
        component={Link}
        to={`/`}
        sx={{
          position: 'fixed',
          right: 20,
          bottom: 20,
        }}
      >
        <LocationCity sx={{ mr: 1 }} />
        Autres villes
      </Fab>
    </Layout>
  );
};
export default VillePage;

export const query = graphql`
  query ($id: String) {
    ville(id: { eq: $id }) {
      nom
      departement
      codePostal
      coordinates {
        latitude
        longitude
      }
      plages {
        id
        nom
        path: gatsbyPath(filePath: "/{Plage.ville__departement}/{Plage.ville__nom}/{Plage.nom}")
        ville {
          nom
        }
      }
    }
    markdownRemark(fields: { ville: { id: { eq: $id } } }) {
      htmlAst
      frontmatter {
        description
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
